

function App() {
  return (
    <div className="flex justify-center items-center flex-1 h-screen bg-black">
      <h1 className='text-5xl font-bold uppercase text-white'>Coming Soon</h1>
    </div>
  );
}

export default App;
